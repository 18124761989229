import React, { memo, FC, useState } from 'react';
import ImportListTable from './import-list-grid';
import { Card, CardContent, CardHeader } from '@mui/material';
import Icon from '$components/icons/icon/icon.react';
import { useNavigate } from 'react-router-dom';
import MuiTextField from '$components/textbox/mui-textfield.react';
import ClickableIcon from '$components/icons/icon-clickable/clickable-icon.react';

interface IImportListCardProps {}

const ImportListCard: FC<IImportListCardProps> = ({}) => {
  const [filter, setFilter] = useState<string>('');
  const nav = useNavigate();
  return (
    <Card className="h-full flex flex-col">
      <CardHeader className="shrink-0" title={'Import jobs'} />

      <div style={{ width: '30%' }} className="p-4">
        <MuiTextField
          value={filter}
          onChange={e => setFilter(e.target.value)}
          placeholder="Search"
          icon={<Icon name="fa-search" />}
          iconEnd={
            filter && (
              <ClickableIcon
                name="fa-times"
                onClick={() => setFilter && setFilter('')}
              />
            )
          }
        />
      </div>

      <CardContent sx={{ padding: 0 }} className="flex grow">
        <ImportListTable
          filter={filter}
          onImportJobClick={(importJobId: string) =>
            nav(`/import/${importJobId}`, { replace: true })
          }
        />
      </CardContent>
    </Card>
  );
};

export default memo(ImportListCard);
