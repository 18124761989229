import React, { memo, FC } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider
} from 'react-router-dom';
import ImportDetails from './import-details/import-details';
import ImportListCard from './components/import-list-card';

export interface IImportProps {}

const ImportComponent: FC<IImportProps> = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="import" errorElement={<Navigate to="import" />}>
        <Route
          index
          element={
            <div className="h-full w-full">
              <ImportListCard />
            </div>
          }
        />
        <Route path=":importJobId" element={<ImportDetails />} />
      </Route>
    )
  );

  return (
    <div className="flex grow p-4">
      <RouterProvider router={router} />
    </div>
  );
};

export default memo(ImportComponent);
