import Icon from '$components/icons/icon/icon.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import ErrorText from '$components/texts/error-text/error-text.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { IGeneralFormError } from '$lib/hooks/reactHookFormHelper';
import {
  ImportJobInfoQuery,
  ImportJobTypesQuery,
  UpdateImportJobDocument
} from '$typings/graphql-codegen';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { memo, FC, useEffect, useState } from 'react';
import {
  Controller,
  FieldError,
  SubmitHandler,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { ImportJobSettingKeyValuePair } from '../types/import-job-settings-key-value-pair';
import {
  getImportJobScheduleKind,
  parseImportJopSettings
} from '../helpers/import-helpers';
import { mutate } from '$lib/hooks/fetch-utillities';
import { ImportJobScheduleKind } from '../types/import-job-schedule-kind';
import { ensureNumber } from '$lib/numberHelpers';
import MuiTextField from '$components/textbox/mui-textfield.react';

interface ImportJobUpdateFormType extends IGeneralFormError {
  repeatMinutes: number | undefined;
  description: string;
  contact: string;
  enabled: boolean;
  settings: ImportJobSettingKeyValuePair[];
}

interface IImportJobFormProps {
  importJob?: ImportJobInfoQuery['importJob'];
  importJobTypes?: ImportJobTypesQuery['importJobTypes'];
  onUpdateSuccess: () => void;
  onCancel: () => void;
  layout?: 'fixed' | 'auto';
}

const ImportJobForm: FC<IImportJobFormProps> = ({
  importJob,
  importJobTypes,
  onUpdateSuccess,
  onCancel,
  layout = 'auto'
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const [scheduleKindTranslated, setScheduleKindTranslated] = useState<string>(
    ''
  );
  const [scheduleKind, setScheduleKind] = useState<ImportJobScheduleKind>();

  useEffect(() => {
    if (importJob && importJobTypes) {
      const importJobType = importJobTypes.find(
        t => t.importTypeId === importJob.importTypeId
      );
      const scheduleKind = getImportJobScheduleKind(importJob, importJobType);
      setScheduleKind(scheduleKind);
      const scheduleKindTranslated =
        scheduleKind === 'continuous'
          ? t('UI_Admin_ImportJob_Schedule_Type_Continuous')
          : scheduleKind === 'repeats'
          ? t('UI_Admin_ImportJob_Schedule_Type_Repeat_TemplateString', {
              interval: importJob.repeatMinutes
            })
          : scheduleKind === 'unknown'
          ? t('UI_Admin_ImportJob_Schedule_Type_Unknown')
          : '';

      setScheduleKindTranslated(scheduleKindTranslated);
    }
  }, [importJob]);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    control
  } = useForm<ImportJobUpdateFormType>({
    values: {
      repeatMinutes: importJob?.repeatMinutes ?? undefined,
      description: importJob?.description ?? '',
      contact: importJob?.contact ?? '',
      enabled: importJob?.enabled ?? false,
      settings: [],
      formError: ''
    }
  });

  const { fields, replace } = useFieldArray({
    control,
    name: 'settings'
  });

  useEffect(() => {
    if (importJob && importJob.settings) {
      const parsedSettings = parseImportJopSettings(importJob.settings);
      replace(parsedSettings);
    }
  }, [importJob]);

  const onSubmit: SubmitHandler<ImportJobUpdateFormType> = async data => {
    const jobSettingsObject = data.settings.reduce((acc, setting) => {
      acc[setting.key] = setting.value;
      return acc;
    }, {} as Record<string, string>);
    const settingsJsonString = JSON.stringify(jobSettingsObject);

    await mutate(
      UpdateImportJobDocument,
      {
        importJob: {
          ...importJob,
          repeatMinutes: data.repeatMinutes
            ? ensureNumber(data?.repeatMinutes)
            : undefined,
          contact: data.contact,
          description: data.description,
          enabled: data.enabled,
          settings: settingsJsonString
        }
      },
      true,
      _ => {
        onUpdateSuccess();
      },
      () => {
        setError('formError', {
          type: 'value',
          message: t('UI_Common_Error_Save_Item_Failed')
        });
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleTable layout={layout}>
        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ImportJob_Name')}
          </SimpleTableCell>
          <SimpleTableCell>{importJob?.name}</SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ImportJob_Type')}
          </SimpleTableCell>
          <SimpleTableCell>{importJob?.importTypeName}</SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          {scheduleKind === 'continuous' ? (
            <>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Admin_ImportJob_Schedule_Type')}
              </SimpleTableCell>
              <SimpleTableCell>{scheduleKindTranslated}</SimpleTableCell>
            </>
          ) : scheduleKind === 'repeats' ? (
            <>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Admin_ImportJob_Schedule_Repeat_Every')}
              </SimpleTableCell>
              <SimpleTableCell className="flex flex-row items-center gap-2">
                <div>
                  <MuiTextField {...register('repeatMinutes')} />
                </div>
                <span>minutes</span>
              </SimpleTableCell>
            </>
          ) : (
            <>
              <SimpleTableCell className="vertical-align-middle">
                {t('UI_Admin_ImportJob_Schedule_Type')}
              </SimpleTableCell>
              <SimpleTableCell>Unknown</SimpleTableCell>
            </>
          )}
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ImportJob_Description')}
          </SimpleTableCell>
          <SimpleTableCell>
            <MuiTextField {...register('description')} />
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ImportJob_Contact')}
          </SimpleTableCell>
          <SimpleTableCell>
            <MuiTextField {...register('contact')} />
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow>
          <SimpleTableCell className="vertical-align-middle">
            {t('UI_Admin_ImportJob_Enabled')}
          </SimpleTableCell>
          <SimpleTableCell>
            <Controller
              name="enabled"
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label={t('UI_Admin_ImportJob_Enabled')}
                  labelPlacement="end"
                  control={<Checkbox checked={value} onChange={onChange} />}
                />
              )}
            />
          </SimpleTableCell>
        </SimpleTableRow>
        {fields.length > 0 && (
          <h1
            style={{
              fontSize: '1.3rem',
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
          >
            Settings
          </h1>
        )}
        {fields.map((field, index) => (
          <SimpleTableRow key={field.id}>
            <SimpleTableCell className="vertical-align-middle">
              {field.key}
            </SimpleTableCell>
            <SimpleTableCell>
              <MuiTextField {...register(`settings.${index}.value`)} />
            </SimpleTableCell>
          </SimpleTableRow>
        ))}
      </SimpleTable>
      <div className="flex justify-between mar-ts">
        <Button onClick={onCancel} variant="outlined" color="primary">
          {t('UI_Common_Cancel')}
        </Button>
        <Button
          startIcon={isSubmitting && <Icon name="fa-spinner fa-pulse" />}
          variant="contained"
          type="submit"
        >
          {t('UI_Common_SaveChanges')}
        </Button>
      </div>
      <ErrorText>{(errors.formError as FieldError)?.message}</ErrorText>
    </form>
  );
};

export default memo(ImportJobForm);
