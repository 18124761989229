import { Card, CardContent, CardHeader } from '@mui/material';
import React, { memo, FC } from 'react';
import ImportJobForm from './import-job-form';
import {
  ImportJobInfoQuery,
  ImportJobTypesQuery
} from '$typings/graphql-codegen';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';

interface IImportJobEditCardProps {
  importJob?: ImportJobInfoQuery['importJob'];
  importJobTypes?: ImportJobTypesQuery['importJobTypes'];
  onUpdateSuccess: () => void;
  onCancel: () => void;
}

const ImportJobEditCard: FC<IImportJobEditCardProps> = ({
  importJob,
  importJobTypes,
  onUpdateSuccess,
  onCancel
}) => {
  const [t] = useCaseInsensitiveTranslation();

  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ fontSize: '1.3rem' }}
        title={t('Edit import job')}
      />
      <CardContent>
        <ImportJobForm
          importJob={importJob}
          importJobTypes={importJobTypes}
          onCancel={onCancel}
          onUpdateSuccess={onUpdateSuccess}
          layout="fixed"
        />
      </CardContent>
    </Card>
  );
};

export default memo(ImportJobEditCard);
