import './import-job-latest-runs-card.css';
import { HistoryList } from '$components/history-list/history-list/history-list.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';
import { ImportJobRunsDocument } from '$typings/graphql-codegen';
import { Card, CardContent, CardHeader } from '@mui/material';
import React, { memo, FC } from 'react';

interface IImportJobLatestRunsCardProps {
  importJobId: string;
}

const ImportJobLatestRunsCard: FC<IImportJobLatestRunsCardProps> = ({
  importJobId
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const { data } = useQuery(ImportJobRunsDocument, {
    importJobId: ensureNumber(importJobId),
    amount: 50
  });

  return (
    <Card>
      <CardHeader
        title={t('UI_Admin_ImportJob_RunHistory_Title')}
        titleTypographyProps={{ fontSize: '1.3rem' }}
      />
      <CardContent className="flex flex-col">
        {data && (
          <>
            <p className="pb-4">
              {data.importJobRuns.length == 0
                ? t('UI_Admin_ImportJob_RunHistory_None')
                : t('UI_Admin_ImportJob_Run_Fetched', {
                    count: data.importJobRuns.length
                  })}
            </p>
            <div className="import-job-latest-runs-list-wrapper w-full">
              <HistoryList
                items={data!.importJobRuns.map(run => ({
                  text: [run.message ?? ''],
                  timeStamp: run.timeStamp!,
                  externalLinks: run.sasReadUrl
                    ? [{ url: run.sasReadUrl, text: 'Download' }]
                    : []
                }))}
              />
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(ImportJobLatestRunsCard);
