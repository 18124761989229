import './import-details.css';
import React, { memo, FC, useState } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import ImportJobDetailsCard from '../components/import-job-details-card';
import ImportJobLatestRunsCard from '../components/import-job-latest-runs-card';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ImportJobInfoDocument,
  ImportJobTypesDocument
} from '$typings/graphql-codegen';
import { useGridData } from '$components/grid/react-grid/gridhelper';
import ImportJobEditCard from '../components/import-job-edit-card';
import { useQuery } from '$lib/hooks/fetch-utillities';
import { ensureNumber } from '$lib/numberHelpers';

interface IImportDetailsProps {}

const ImportDetails: FC<IImportDetailsProps> = () => {
  const [t] = useCaseInsensitiveTranslation();
  const { importJobId } = useParams<string>();
  const [isEditDetails, setIsEditDetails] = useState<boolean>(false);
  const nav = useNavigate();

  const { data: importJob } = useQuery(ImportJobInfoDocument, {
    importJobId: ensureNumber(importJobId!)
  });

  const { data: importJobTypes } = useGridData(
    ImportJobTypesDocument,
    {},
    data => data!.importJobTypes!.length!,
    data => data!.importJobTypes!
  );

  return (
    <div className="flex flex-col w-full gap-4">
      <div>
        <Card>
          <CardContent>
            <Button onClick={() => nav('/import')} variant="outlined">
              {t('UI_ImportJob_Return')}
            </Button>
          </CardContent>
        </Card>
      </div>
      <div className="flex gap-4">
        <div style={{ maxWidth: '600px' }} className="flex flex-col gap-4">
          {isEditDetails ? (
            <ImportJobEditCard
              importJob={importJob?.importJob}
              importJobTypes={importJobTypes}
              onCancel={() => setIsEditDetails(false)}
              onUpdateSuccess={() => setIsEditDetails(false)}
            />
          ) : (
            <ImportJobDetailsCard
              importJob={importJob?.importJob}
              importJobTypes={importJobTypes}
              onEditClick={() => setIsEditDetails(true)}
            />
          )}
        </div>
        <div>
          <ImportJobLatestRunsCard importJobId={importJobId!} />
        </div>
      </div>
    </div>
  );
};

export default memo(ImportDetails);
