import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import React, { memo, FC, useEffect, useState } from 'react';
import { parseImportJopSettings } from '../helpers/import-helpers';

interface IImportJobSettingKeyValuePair {
  key: string;
  value: string;
}

interface IImportJobSettingsTableProps {
  importJobSettingsJson: string;
}

const ImportJobSettingsTable: FC<IImportJobSettingsTableProps> = ({
  importJobSettingsJson
}) => {
  const [settings, setSettings] = useState<
    IImportJobSettingKeyValuePair[] | undefined
  >(undefined);

  useEffect(() => {
    const parsedSettings = parseImportJopSettings(importJobSettingsJson);
    setSettings(parsedSettings);
  }, [importJobSettingsJson]);

  return (
    <>
      <SimpleTable layout="fixed">
        {settings?.map(setting => (
          <SimpleTableRow key={setting.key}>
            <SimpleTableCell>{setting.key}</SimpleTableCell>
            <SimpleTableCell>{setting.value}</SimpleTableCell>
          </SimpleTableRow>
        ))}
      </SimpleTable>
    </>
  );
};

export default memo(ImportJobSettingsTable);
