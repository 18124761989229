import Icon from '$components/icons/icon/icon.react';
import SimpleTableCell from '$components/tables/simple-table/simple-table-cell/simple-table-cell.react';
import SimpleTableRow from '$components/tables/simple-table/simple-table-row/simple-table-row.react';
import SimpleTable from '$components/tables/simple-table/simple-table/simple-table.react';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  ImportJobInfoQuery,
  ImportJobTypesQuery
} from '$typings/graphql-codegen';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Skeleton
} from '@mui/material';
import React, { memo, FC, useState, useEffect } from 'react';
import { getUserFeatures } from '../../../config/sessionService';
import {
  getImportJobScheduleKind,
  parseImportJopSettings
} from '../helpers/import-helpers';
import ImportJobSettingsTable from './import-job-settings-table';

interface IImportJobDetailsCardProps {
  importJob?: ImportJobInfoQuery['importJob'];
  importJobTypes?: ImportJobTypesQuery['importJobTypes'];
  onEditClick: () => void;
}

const ImportJobDetailsCard: FC<IImportJobDetailsCardProps> = ({
  importJob,
  importJobTypes,
  onEditClick
}) => {
  const [t] = useCaseInsensitiveTranslation();
  const features = getUserFeatures();
  const [scheduleKindTranslated, setScheduleKindTranslated] = useState<string>(
    ''
  );
  const [hasSettings, setHasSettings] = useState<boolean>(false);

  useEffect(() => {
    if (importJob && importJobTypes) {
      const importJobType = importJobTypes.find(
        t => t.importTypeId === importJob.importTypeId
      );
      const scheduleKind = getImportJobScheduleKind(importJob, importJobType);
      const scheduleKindTranslated =
        scheduleKind === 'continuous'
          ? t('UI_Admin_ImportJob_Schedule_Type_Continuous')
          : scheduleKind === 'repeats'
          ? t('UI_Admin_ImportJob_Schedule_Type_Repeat_TemplateString', {
              interval: importJob.repeatMinutes
            })
          : scheduleKind === 'unknown'
          ? t('UI_Admin_ImportJob_Schedule_Type_Unknown')
          : '';

      setScheduleKindTranslated(scheduleKindTranslated);

      if (importJob.settings) {
        const parsedSettings = parseImportJopSettings(importJob.settings);
        if (parsedSettings.length > 0) setHasSettings(true);
      }
    }
  }, [importJob, importJobTypes]);

  return (
    <Card>
      <CardContent className="flex justify-between">
        <CardHeader
          sx={{ padding: 0 }}
          titleTypographyProps={{ fontSize: '1.3rem' }}
          title={'View import job'}
        />
        {features.importManagement.write && (
          <Icon onClick={onEditClick} name={'fa fa-pencil'} />
        )}
      </CardContent>
      <CardContent>
        {importJob ? (
          <SimpleTable layout="fixed">
            <SimpleTableRow>
              <SimpleTableCell>{t('UI_Admin_ImportJob_Name')}</SimpleTableCell>
              <SimpleTableCell>{importJob.name}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>{t('UI_Admin_ImportJob_Type')}</SimpleTableCell>
              <SimpleTableCell>{importJob.importTypeName}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ImportJob_Schedule_Type')}
              </SimpleTableCell>
              <SimpleTableCell>{scheduleKindTranslated}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ImportJob_Description')}
              </SimpleTableCell>
              <SimpleTableCell>{importJob.description}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ImportJob_Contact')}
              </SimpleTableCell>
              <SimpleTableCell>{importJob.contact}</SimpleTableCell>
            </SimpleTableRow>
            <SimpleTableRow>
              <SimpleTableCell>
                {t('UI_Admin_ImportJob_Enabled')}
              </SimpleTableCell>
              <SimpleTableCell>
                <Checkbox
                  sx={{ padding: 0 }}
                  disabled
                  checked={importJob.enabled!}
                />
              </SimpleTableCell>
            </SimpleTableRow>
          </SimpleTable>
        ) : (
          <SimpleTable>
            {Array.from({ length: 6 }).map((_, index) => (
              <React.Fragment key={index}>
                <SimpleTableRow>
                  <SimpleTableCell>
                    <Skeleton />
                  </SimpleTableCell>
                  <SimpleTableCell>
                    <Skeleton />
                  </SimpleTableCell>
                </SimpleTableRow>
              </React.Fragment>
            ))}
          </SimpleTable>
        )}
        {importJob && hasSettings && (
          <>
            <h1
              style={{
                fontSize: '1.3rem',
                marginTop: '2rem',
                marginBottom: '2rem'
              }}
            >
              Settings
            </h1>
            <ImportJobSettingsTable
              importJobSettingsJson={importJob.settings!}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default memo(ImportJobDetailsCard);
