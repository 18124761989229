import { useGridData } from '$components/grid/react-grid/gridhelper';
import Icon from '$components/icons/icon/icon.react';
import {
  AllImportJobsDocument,
  AllImportJobsQuery,
  ImportJob,
  ImportJobTypesDocument,
  ImportJobTypesQuery,
  SortDirection
} from '$typings/graphql-codegen';
import React, { memo, FC, useState, useMemo } from 'react';
import Grid from '$components/grid/react-grid/grid.react';
import GridCell from '$components/grid/react-grid/cell/grid-cell';
import { makeSortedData } from '$pages/reports/makeSortedData';
import { Checkbox } from '@mui/material';
import {
  getImportJobScheduleKind,
  makeSortWarningData
} from '../helpers/import-helpers';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import { ensureDate, formatDateTime } from '$lib/dateHelpers';

type ImportJobsListSortColumnKeys =
  | 'warning'
  | 'enabled'
  | 'name'
  | 'importTypeName'
  | 'lastRun'
  | 'lastDuration'
  | 'lastResult'
  | 'success'
  | 'scheduleType';

type ImportJobsListSortValues = {
  sortColumnKey: ImportJobsListSortColumnKeys;
  sortDirection: SortDirection;
};

type SortOutputType = Array<ImportJob>;

const sortImportJobsList = (
  rows: Array<ImportJob | undefined | null>,
  sortDirection: SortDirection,
  sortColumnKey: ImportJobsListSortColumnKeys
): SortOutputType | undefined => {
  if (!rows) return rows;
  const saneRows: SortOutputType = rows.filter(
    row => row !== undefined && row !== null
  ) as SortOutputType;
  const direction = sortDirection === SortDirection.Asc ? 'asc' : 'desc';

  if (sortColumnKey == 'warning') {
    return makeSortWarningData(saneRows, direction, sortColumnKey) as
      | SortOutputType
      | undefined;
  }

  if (
    sortColumnKey == 'enabled' ||
    sortColumnKey == 'name' ||
    sortColumnKey == 'scheduleType' ||
    sortColumnKey == 'lastRun' ||
    sortColumnKey == 'lastDuration' ||
    sortColumnKey == 'lastResult' ||
    sortColumnKey == 'success' ||
    sortColumnKey == 'importTypeName'
  ) {
    return makeSortedData(saneRows, direction, sortColumnKey) as
      | SortOutputType
      | undefined;
  }
  return saneRows;
};

interface IImportListGridProps {
  onImportJobClick: (jobId: string) => void;
  filter?: string;
}

const ImportListGrid: FC<IImportListGridProps> = ({
  onImportJobClick,
  filter
}) => {
  const [t] = useCaseInsensitiveTranslation();

  const data = useGridData(
    AllImportJobsDocument,
    {},
    data => data!.importJobs!.length!,
    data => data!.importJobs!
  );

  const { data: importJobTypes } = useGridData(
    ImportJobTypesDocument,
    {},
    data => data!.importJobTypes!.length!,
    data => data!.importJobTypes!
  );

  const findAndGetImportJobScheduleKind = (
    importJob?: AllImportJobsQuery['importJobs'][0],
    importJobTypes?: ImportJobTypesQuery['importJobTypes']
  ) => {
    if (importJob === undefined || importJobTypes === undefined)
      return 'unknown';
    const importJobType = importJobTypes.find(
      t => t.importTypeId === importJob.importTypeId
    );

    const scheduleKind = getImportJobScheduleKind(importJob, importJobType);

    return scheduleKind === 'continuous'
      ? t('UI_Admin_ImportJob_Schedule_Type_Continuous')
      : scheduleKind === 'repeats'
      ? t('UI_Admin_ImportJob_Schedule_Type_Repeat_TemplateString', {
          interval: importJob.repeatMinutes
        })
      : scheduleKind === 'unknown'
      ? t('UI_Admin_ImportJob_Schedule_Type_Unknown')
      : '';
  };

  const [sortValues, setSortValues] = useState<ImportJobsListSortValues>({
    sortColumnKey: 'name',
    sortDirection: SortDirection.Asc
  });

  const filteredData = useMemo(() => {
    const searchFor = filter?.toLowerCase();
    return searchFor
      ? data?.data?.filter(
          e =>
            (e.name && e.name.toLowerCase().indexOf(searchFor) >= 0) ||
            (e.contact && e.contact.toLowerCase().indexOf(searchFor) >= 0) ||
            (e.description &&
              e.description.toLowerCase().indexOf(searchFor) >= 0)
        ) ?? []
      : data.data;
  }, [data, filter]);

  const orderedData = {
    ...data,
    data: filteredData
      ? sortImportJobsList(
          filteredData,
          sortValues.sortDirection,
          sortValues.sortColumnKey
        )
      : undefined
  };

  const rowLink = (row: ImportJob) => {
    if (!row) return;
    return `import/${row.importJobId}`;
  };

  return (
    <>
      <Grid
        highlightText={filter}
        loading={data.isRevalidating}
        className="grow"
        name={'import-jobs-grid'}
        items={orderedData}
        columns={[
          {
            columnTitle: 'UI_Admin_ImportJob_Warning',
            columnKey: 'warning',
            initialWidth: 100,
            render: (job, width) => (
              <GridCell width={width}>
                {job.notRunRecently && <Icon name="fa-exclamation-triangle" />}
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_Enabled',
            columnKey: 'enabled',
            initialWidth: 100,
            render: (job, width) => (
              <GridCell width={width}>
                <Checkbox
                  sx={{ padding: 0 }}
                  disabled
                  checked={job.enabled ?? false}
                />
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_Name',
            columnKey: 'name',
            initialWidth: 'auto',
            render: (job, width) => (
              <GridCell style={{ minWidth: 100 }} width={width}>
                {job.name}
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_Type',
            columnKey: 'importTypeName',
            initialWidth: 150,
            render: (job, width) => (
              <GridCell style={{ minWidth: 150 }} width={width}>
                {job.importTypeName}
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_Schedule',
            columnKey: 'scheduleType',
            initialWidth: 150,
            render: (job, width) => (
              <GridCell style={{ minWidth: 150 }} width={width}>
                {findAndGetImportJobScheduleKind(job, importJobTypes)}
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_LastRunningTime',
            columnKey: 'lastRun',
            initialWidth: 150,
            render: (job, width) => (
              <GridCell style={{ minWidth: 150 }} width={width}>
                {job.lastRun &&
                  formatDateTime(
                    ensureDate(job.lastRun!),
                    'dd.MM.yyyy HH:mm',
                    undefined
                  )}
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_LastDuration',
            columnKey: 'lastDuration',
            initialWidth: 150,
            render: (job, width) => (
              <GridCell style={{ minWidth: 150 }} width={width}>
                {job.lastDuration}
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_LastResult',
            columnKey: 'lastResult',
            initialWidth: 150,
            render: (job, width) => (
              <GridCell
                width={width}
                style={{ whiteSpace: 'nowrap', minWidth: 150 }}
              >
                {job.lastResult}
              </GridCell>
            )
          },
          {
            columnTitle: 'UI_Admin_ImportJob_LastRunSuccess',
            columnKey: 'success',
            initialWidth: 150,
            render: (job, width) => (
              <GridCell style={{ minWidth: 150 }} width={width}>
                {job.success
                  ? 'Success'
                  : job.lastResult
                  ? 'Last run failed'
                  : ''}
              </GridCell>
            )
          }
        ]}
        sortedColumnKey={sortValues.sortColumnKey}
        sortedDirection={sortValues?.sortDirection}
        sortChanged={(sortByColumn, sortDirection) =>
          setSortValues({
            sortColumnKey: sortByColumn,
            sortDirection: sortDirection
          })
        }
        onRowClick={row => () => onImportJobClick(row.importJobId!.toString())}
        rowLink={rowLink}
      />
    </>
  );
};

export default memo(ImportListGrid);
